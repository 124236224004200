import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { doEncode } from '../generalFunctions';
import { stepObjName } from '../variables/generalVariables';
import Dropdown from '../Dropdown/Dropdown';
import './NihssStep.scss';

const ageOptions = (t) =>
    Array.from({ length: 120 }, (_, i) => `${120 - i} ${t('general.years')}`);

const NihssStep = (props) => {
    const { stepObj, nextObj, updateScore, goToPage, age, setAge, t } = props;
    const [wasSelected, setWasSelected] = useState(undefined);
    const location = useLocation();

    useEffect(() => {
        let hasScoreBoard = sessionStorage.getItem('estroke-scoreboard');
        if (hasScoreBoard && hasScoreBoard[stepObjName(stepObj)]) {
            setWasSelected(hasScoreBoard[stepObjName(stepObj)]);
        }
    }, [stepObj]);

    const onNext = (url, e, opt, dir) => {
        //use score and save to main component to use for total.
        let setImg;
        if (opt.img) setImg = opt.img;
        else if (opt.imgs && dir) setImg = opt.imgs[dir];
        updateScore(stepObjName(stepObj), {
            score: opt.score,
            direction: dir,
            nihssNo: stepObj.refId,
            title: stepObj.title,
            img: setImg
        });
        setWasSelected({
            score: opt.score,
            nihssNo: stepObj.refId,
            title: opt.title,
            img: setImg
        });
        if (location.pathname !== '/1b-1') goToPage(url);
    };

    return (
        <div className='step-question'>
            <h1>{stepObj.title}</h1>
            <p className='step-task'>{stepObj.task}</p>
            <div className='answers-opt'>
                {stepObj.answerOpts.map((opt, ind) => {
                    return (
                        <div
                            key={ind}
                            className={`answer-opts setwidth-${
                                opt.width || 'standard'
                            }`}
                        >
                            {(opt.img || opt.imgs) && (
                                <div className='img-wrapper'>
                                    {opt.img && (
                                        <button
                                            onClick={(e) =>
                                                onNext(
                                                    `/${doEncode(
                                                        nextObj
                                                            ? stepObjName(
                                                                  nextObj
                                                              )
                                                            : 'vitalia'
                                                    )}`,
                                                    e,
                                                    opt
                                                )
                                            }
                                            className={
                                                wasSelected &&
                                                wasSelected.title === opt.title
                                                    ? 'previously-selected'
                                                    : ''
                                            }
                                        >
                                            <img
                                                src={`images/${opt.img}.svg`}
                                                alt=''
                                            />
                                        </button>
                                    )}
                                    {opt.imgs && (
                                        <Fragment>
                                            {opt.imgs.right && (
                                                <button
                                                    onClick={(e) =>
                                                        onNext(
                                                            `/${doEncode(
                                                                nextObj
                                                                    ? stepObjName(
                                                                          nextObj
                                                                      )
                                                                    : 'vitalia'
                                                            )}`,
                                                            e,
                                                            opt,
                                                            'right'
                                                        )
                                                    }
                                                    className={`imgs-pl${
                                                        wasSelected &&
                                                        wasSelected.score ===
                                                            opt.score &&
                                                        wasSelected.direction ===
                                                            'right'
                                                            ? ' previously-selected'
                                                            : ''
                                                    }`}
                                                >
                                                    <img
                                                        src={`images/${opt.imgs.right}.svg`}
                                                        alt=''
                                                    />
                                                    <p className='opt-side-txt'>
                                                        {t('general.right')}
                                                    </p>
                                                </button>
                                            )}
                                            {opt.imgs.left && (
                                                <button
                                                    onClick={(e) =>
                                                        onNext(
                                                            `/${doEncode(
                                                                nextObj
                                                                    ? stepObjName(
                                                                          nextObj
                                                                      )
                                                                    : 'vitalia'
                                                            )}`,
                                                            e,
                                                            opt,
                                                            'left'
                                                        )
                                                    }
                                                    className={`imgs-pl${
                                                        wasSelected &&
                                                        wasSelected.score ===
                                                            opt.score &&
                                                        wasSelected.direction ===
                                                            'left'
                                                            ? ' previously-selected'
                                                            : ''
                                                    }`}
                                                >
                                                    <img
                                                        src={`images/${opt.imgs.left}.svg`}
                                                        alt=''
                                                    />
                                                    <p className='opt-side-txt'>
                                                        {t('general.left')}
                                                    </p>
                                                </button>
                                            )}
                                        </Fragment>
                                    )}
                                </div>
                            )}
                            <h2>{opt.title}</h2>
                            <p className='score-points'>{opt.score}</p>
                        </div>
                    );
                })}
            </div>
            {['/11-1', '/11-2'].includes(location.pathname) && (
                <p>{t('form.attention.footer')}</p>
            )}
            {location.pathname === '/1b-1' && (
                <>
                    <Dropdown
                        label={t('form.age_orientation.patient')}
                        options={ageOptions(t)}
                        onChange={(e) => setAge(e.target.value)}
                        age={age}
                    />
                    <button
                        className='next-btn'
                        onClick={() =>
                            goToPage(
                                `/${doEncode(
                                    nextObj ? stepObjName(nextObj) : 'vitalia'
                                )}`
                            )
                        }
                        disabled={wasSelected === undefined || age === ''}
                    >
                        <span>{t('button.next')}</span>
                    </button>
                </>
            )}
        </div>
    );
};

export default NihssStep;
