import React from 'react';
import './StartPage.scss';
import { emptySession } from '../variables/sessionVariables';

const StartPage = (props) => {
    const { toNext, t } = props;
    const onChange = (e) => {
        emptySession();
        toNext();
    };

    return (
        <div className='StartPage'>
            <h1>{t('web.general.title')}</h1>
            <p>{t('web.general.subtitle')}</p>

            <div className='versionSelection'>
                <button className='mobileversion' onClick={onChange}>
                    <img src='images/estroke.png' alt='Mobil-app' />
                    <span>{t('web.general.mobileApp')}</span>
                </button>
            </div>
        </div>
    );
};

export default StartPage;
