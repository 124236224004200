export const isValidBirthNo = (fodselsnr) => {
    fodselsnr = fodselsnr.replace(/\s/g, '');

    if (fodselsnr.length !== 11) {
        return false;
    }

    function sum(birthNumber, factors) {
        return factors.reduce(function (sum, factor, i) {
            return sum + parseInt(birthNumber.charAt(i), 10) * factor;
        }, 0);
    }

    const arr1 = [3, 7, 6, 1, 8, 9, 4, 5, 2, 1];
    const arr2 = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2, 1];
    const chk1 = sum(fodselsnr, arr1);
    const chk2 = sum(fodselsnr, arr2);

    const mod1 = chk1 % 11;
    const mod2 = chk2 % 11;

    return mod1 === 0 && mod2 === 0;
};
class Prescription {
    constructor(lbl, name) {
        this.lbl = lbl;
        this.name = name;
    }
}
export const legemidler = (t) => [
    new Prescription(t('form.medication.none'), 'None'),
    new Prescription(t('form.medication.unknown'), 'Unknown'),
    new Prescription(t('form.medication.apixaban'), 'A1'),
    new Prescription(t('form.medication.dabigatran'), 'D1'),
    new Prescription(t('form.medication.edoxaban'), 'E1'),
    new Prescription(t('form.medication.rivaroxaban'), 'R1'),
    new Prescription(t('form.medication.warfarin'), 'W1'),
    new Prescription(t('form.medication.acetylsalicylic_acid'), 'A2'),
    new Prescription(t('form.medication.clopidogrel'), 'C1'),
    new Prescription(t('form.medication.dipyridamole'), 'D2'),
    new Prescription(t('form.medication.heparin'), 'H1'),
    new Prescription(t('web.steps.medication.others'), 'Others')
];
