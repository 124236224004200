import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { stepArr, stepUrl } from '../variables/generalVariables';
import './HistoryMenu.scss';

let steps,
    stepsWeb,
    stepsMobile = [];

function initSteps(stepArray) {
    /*
     * stepsWeb: List of steps for web version
     */
    stepsWeb = [
        { url: '/nypasient', label: 'Ny pasient' },
        { url: '/identifiser', label: 'Identifiser' },
        { url: '/symptomdebut', label: 'Symptomdebut' },
        { url: '/medikamenter', label: 'Medikamenter' },
        { url: '/pasient', label: 'Pasient' }
    ];

    stepArray.forEach((step) => {
        stepsWeb.push({ url: stepUrl(step), label: step.title });
    });

    stepsWeb = [
        ...stepsWeb,
        { url: '/vitalia', label: 'Vitalia' },
        { url: '/oppsummering', label: 'Oppsummering' }
    ];

    /*
     * stepsMobile: List of steps for mobile version
     * In the mobile version, the 'Identifiser' step is skipped
     */
    stepsMobile = [...stepsWeb];
    stepsMobile.splice(
        stepsMobile.findIndex((step) => step.url === '/identifiser'),
        1
    );

    return { stepsWeb, stepsMobile };
}

const HistoryMenu = (props) => {
    const { goToPage, version, t } = props;
    const stepArray = stepArr(t);
    const [showHistory, setShowHistory] = useState(false);
    const [historyList, setHistoryList] = useState(() => {
        const savedHistory = sessionStorage.getItem('history');
        return savedHistory ? JSON.parse(savedHistory) : [];
    });
    const location = useLocation();

    initSteps(stepArray);

    steps = version === 'mobile' ? stepsMobile : stepsWeb;

    useEffect(() => {
        setHistoryList((prev) => {
            if (!prev.some((item) => item.url === location.pathname)) {
                const stepLabel = steps.find(
                    (step) => step.url === location.pathname
                )?.label;
                const newHistory = [
                    ...prev,
                    {
                        url: location.pathname,
                        label: stepLabel
                    }
                ];
                sessionStorage.setItem('history', JSON.stringify(newHistory));
                return newHistory;
            } else {
                return prev;
            }
        });
    }, [location]);

    return (
        <>
            {showHistory && (
                <div className='history-overlay'>
                    <div className='history-menu'>
                        <div className='item-title'>
                            {
                                historyList.find(
                                    (item) => item.url === location.pathname
                                ).label
                            }
                        </div>
                        {historyList.map((page, index) => (
                            <div
                                key={index}
                                className={`history-item ${
                                    location.pathname === page.url
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() => {
                                    goToPage(page.url);
                                    setShowHistory(false);
                                }}
                            >
                                {page.label}{' '}
                            </div>
                        ))}
                    </div>
                    <button
                        className='history-cancel'
                        onClick={() => setShowHistory(false)}
                    >
                        Avbryt
                    </button>
                </div>
            )}
            {!showHistory && (
                <button
                    className='history-btn'
                    onClick={() => setShowHistory(true)}
                >
                    {steps.findIndex((step) => step.url === location.pathname) +
                        1}{' '}
                    / {steps.length}
                </button>
            )}
        </>
    );
};

export default HistoryMenu;
