import React from 'react';
import VitaliaErrorMsg from '../VitaliaErrorMsg/VitaliaErrorMsg';

const GenericVitaliaInput = (props) => {
    const {
        obj,
        firstTitle,
        firstValue,
        changeFirst,
        firstMin,
        firstMax,
        firstIsValid,
        secondTitle,
        secondValue,
        changeSecond,
        secondMin,
        secondMax,
        secondIsValid,
        maxValue,
        seperator,
        t
    } = props;
    return (
        <section
            className={`vitalia-item set-verdi ${
                obj.known ? 'is-known' : 'is-unknown'
            }`}
        >
            <h3>{t('web.vitals.value')}</h3>
            <div className='bp-input'>
                <label aria-label={firstTitle}>
                    <input
                        type='number'
                        pattern='[0-9]{1,3}'
                        name={firstTitle}
                        value={firstValue || ''}
                        onChange={changeFirst}
                        disabled={obj.known === false}
                        min={firstMin}
                        max={firstMax}
                        required={obj.known}
                    />
                    {obj.known && (
                        <VitaliaErrorMsg
                            show={firstIsValid}
                            min={firstMin}
                            max={firstMax}
                            t={t}
                        />
                    )}
                </label>
                <span style={{ color: 'grey' }}>{seperator}</span>
                <label aria-label={secondTitle}>
                    <input
                        type='number'
                        pattern='[0-9]{1,3}'
                        name={secondTitle}
                        value={secondValue || ''}
                        onChange={changeSecond}
                        disabled={obj.known === false}
                        min={secondMin}
                        max={
                            parseInt(maxValue) === parseInt(firstValue)
                                ? 0
                                : secondMax
                        }
                        required={obj.known}
                    />
                    {obj.known && (
                        <VitaliaErrorMsg
                            show={secondIsValid}
                            min={secondMin}
                            max={
                                parseInt(maxValue) === parseInt(firstValue)
                                    ? 0
                                    : secondMax
                            }
                            t={t}
                        />
                    )}
                </label>
            </div>
        </section>
    );
};

export default GenericVitaliaInput;
