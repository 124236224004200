import React, { useState, useEffect } from 'react';
import { emptySession } from '../variables/sessionVariables';
import './Summary.scss';

const Summary = (props) => {
    const { scoreBoard, age, toNext, t } = props;
    const [totalScore, setTotalScore] = useState(0);
    const [mappedVals, setMappedVals] = useState([]);
    const [showScoreImg, setShowScoreImg] = useState(true);

    const onChange = (e) => {
        emptySession();
        toNext();
    };

    useEffect(() => {
        const sVal = Object.values(scoreBoard).reduce(
            (total, obj) => total + (obj.score || 0),
            0
        );
        setTotalScore(sVal);

        let sortable = [];
        for (let score in scoreBoard) {
            if (scoreBoard[score])
                sortable.push({ ...scoreBoard[score], val: score });
        }
        sortable.sort((a, b) => {
            return b.score - a.score;
        });
        setMappedVals(sortable);
    }, [scoreBoard]);

    // const sendToApi = (e) => {
    //     const sendbody = {
    //         NihssEntry: scoreBoard,
    //         StartDateTime: dateValForApi(vitalia.symptomDebut)
    //     };
    //     if (caseInfo && caseInfo.CaseId) sendbody['CaseId'] = caseInfo.CaseId;
    //     //saveChangesStroke(sendbody);
    //     if (getVersionS() === 'web') {
    //         alert('Kun test - ingen PDF laget');
    //     } else {
    //         alert('Kun test - ingen sms er sendt');
    //     }
    // };

    const toggleScoreImg = () => {
        setShowScoreImg(showScoreImg ? false : true);
    };

    return (
        <div
            className={`summary-page ${
                showScoreImg ? 'showScoreImg' : 'hideScoreImg'
            }`}
        >
            <h1>{t('web.summary.title')}</h1>
            <div className='score-sum'>
                <img
                    src={`images/Oppsummering.svg`}
                    alt=''
                    className='bck-img'
                />
                <h2>{totalScore || ''}</h2>
                <p style={{ paddingTop: '15px', fontSize: '15px' }}>NIHSS</p>
            </div>
            <ul className='summary-list-info list-medicinfo'>
                <li className='li-el-medicines'>
                    <span className='left'>{age}</span>
                </li>
            </ul>
            {mappedVals && mappedVals.length > 0 ? (
                <>
                    <p className='grey-heading-section'>
                        <button
                            className='toggleScoreImg'
                            onClick={toggleScoreImg}
                        >
                            {t('web.summary.show_hide_img')}
                        </button>
                    </p>

                    <ul className='summary-list'>
                        {mappedVals.map((obj) => {
                            return (
                                <React.Fragment key={obj.val}>
                                    {obj.score > 0 && (
                                        <li key={obj.val}>
                                            <img
                                                src={`images/${obj.img}.svg`}
                                                alt=''
                                            />
                                            <span className='origin-sum'>
                                                {obj.title || obj.val}:
                                            </span>
                                            <span className='score-number red-txt'>
                                                {obj.score}
                                                {obj.direction === 'left' &&
                                                    '-v'}
                                                {obj.direction === 'right' &&
                                                    '-h'}
                                            </span>
                                        </li>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </>
            ) : (
                <p>{t('web.summary.error_msg.no_data')}</p>
            )}
            <button type='button' className='next-btn' onClick={onChange}>
                {t('web.summary.btns.end_survey')}
            </button>
        </div>
    );
};

export default Summary;
