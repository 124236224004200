import React from 'react';
import InputSwitch from '../InputSwitch/InputSwitch';

const GenericVitaliaDisplay = (props) => {
    const { title, obj, changeCheck, info, t } = props;
    return (
        <ul className='vitalia-list-page'>
            <li className='list-section'>
                <div>
                    <h2>{title}</h2>
                </div>
                <div className='value'>
                    <p>{obj.known ? info : t('general.unknown')}</p>
                </div>
            </li>
            <li className='list-section'>
                <InputSwitch
                    checked={obj.known === false}
                    setChecked={changeCheck}
                    lbl={obj.lbl}
                    name={obj.title + '-switch'}
                />
            </li>
        </ul>
    );
};

export default GenericVitaliaDisplay;
