import React from 'react';
import './VitaliaErrorMsg.scss';

const VitaliaErrorMsg = ({ show, min, max, t }) => {
    if (show === null || show === true) {
        return null;
    }

    return (
        <span className='error-msg'>
            {min === max
                ? t('web.vitals.err_msg_1')
                : `${t('web.vitals.err_msg_2')} ${min}-${max}`}
        </span>
    );
};

export default VitaliaErrorMsg;
