import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { stepArr, stepUrl } from '../variablesEN/generalVariables';
import './HistoryMenu.scss';

let steps = [];

function initSteps(stepArray, t) {
    let x = [];
    stepArray.forEach((step) => {
        x.push({ url: stepUrl(step), label: step.title });
    });

    x = [...x, { url: '/oppsummering', label: t('button.summary') }];

    return x;
}
const HistoryMenu = (props) => {
    const { goToPage, t } = props;
    const stepArray = stepArr(t);
    const [showHistory, setShowHistory] = useState(false);
    const [historyList, setHistoryList] = useState(() => {
        const savedHistory = sessionStorage.getItem('history');
        return savedHistory ? JSON.parse(savedHistory) : [];
    });
    const location = useLocation();

    steps = initSteps(stepArray, t);

    useEffect(() => {
        setHistoryList((prev) => {
            if (!prev.some((item) => item.url === location.pathname)) {
                const stepLabel = steps.find(
                    (step) => step.url === location.pathname
                )?.label;

                const newHistory = [
                    ...prev,
                    {
                        url: location.pathname,
                        label: stepLabel
                    }
                ];
                sessionStorage.setItem('history', JSON.stringify(newHistory));
                return newHistory;
            } else {
                return prev;
            }
        });
    }, [location]);

    return (
        <>
            {showHistory && (
                <div className='history-overlay'>
                    <div className='history-menu'>
                        <div className='item-title'>
                            {
                                historyList.find(
                                    (item) => item.url === location.pathname
                                ).label
                            }
                        </div>
                        {historyList.map((page, index) => (
                            <div
                                key={index}
                                className={`history-item ${
                                    location.pathname === page.url
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() => {
                                    goToPage(page.url);
                                    setShowHistory(false);
                                }}
                            >
                                {page.label}{' '}
                            </div>
                        ))}
                    </div>
                    <button
                        className='history-cancel'
                        onClick={() => setShowHistory(false)}
                    >
                        Avbryt
                    </button>
                </div>
            )}
            {!showHistory && (
                <button
                    className='history-btn'
                    onClick={() => setShowHistory(true)}
                >
                    {steps.findIndex((step) => step.url === location.pathname) +
                        1}{' '}
                    / {steps.length}
                </button>
            )}
        </>
    );
};

export default HistoryMenu;
