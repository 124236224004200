const domainLocaleMap = {
    'estroke.org': 'en',
    'estroke.no': 'nb'
};

export const languageByDomainDetector = (DEFAULT_LOCALE) => ({
    type: 'languageDetector',
    detect: (req) => {
        let locale = DEFAULT_LOCALE;

        if (typeof window !== 'undefined') {
            locale = domainLocaleMap[window.location.hostname];
        } else {
            const hostname = req.headers.host?.split(':')[0];
            locale = domainLocaleMap[hostname];
        }

        return locale;
    },
    init: () => {},
    cacheUserLanguage: () => {}
});
