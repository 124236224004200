import React from 'react';
import './SymptomDebut.scss';
import InputSwitch from '../InputSwitch/InputSwitch';
import { InputFullDate } from '../InputDateFields/InputDateFields';

const SymptomDebut = (props) => {
    const { nextUrl, vitalia, setVitalia, loadFields, goToPage, t } = props;

    const setDebutTime = (val) => {
        setVitalia('symptomDebut', val);
    };
    const setCheckedUnknown = (val) => {
        setVitalia('unknownDebut', val);
    };
    const validatePage = () => {
        setVitalia('sbValidated', true);
        goToPage(nextUrl);
    };

    return (
        <div className='symptom-debut'>
            <h1>{t('form.symptoms.title')}</h1>
            <p>{t('form.symptoms.time')}</p>
            {vitalia && vitalia.symptomDebut && loadFields && (
                <InputFullDate
                    inDate={vitalia.symptomDebut}
                    changeDate={setDebutTime}
                    disabled={vitalia.unknownDebut}
                    t={t}
                />
            )}
            {vitalia && (
                <InputSwitch
                    checked={vitalia.unknownDebut}
                    setChecked={setCheckedUnknown}
                    lbl={t('form.symptoms.symptom_unknown')}
                    name='symptomhasdebut'
                />
            )}
            <button onClick={validatePage} className='next-btn'>
                <span>{t('web.general.btns.next')}</span>
            </button>
        </div>
    );
};

export default SymptomDebut;
