import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { stepArr, stepUrl } from '../variablesEN/generalVariables';
import HistoryMenuEN from '../HistoryMenuEN/HistoryMenu';
import { emptySession } from '../variablesEN/sessionVariables';
import './Header.scss';

const UrlButton = (props) => {
    const { onClick, url, classes, isDisabled } = props;

    return (
        <button
            className={`url-step ${classes || ''}`}
            onClick={() => onClick(url)}
            disabled={isDisabled}
        >
            {props.children}
        </button>
    );
};

const Header = (props) => {
    const { history, goToPage, scoreBoard, t } = props;
    const [scoreBoardKeys, setScoreBoardKeys] = useState([]);
    const [sidemenuOpen, setSideMenuOpen] = useState(false);
    const [nihhsMenuOpen, setNihhsMenuOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState({
        summary: true
    });
    const location = useLocation();
    const stepArray = stepArr(t);

    useEffect(() => {
        const arr = Object.keys(scoreBoard).map((key) => key);
        setScoreBoardKeys(arr);
        let changeAny = false;

        let disabledMap = { ...isDisabled };
        if (arr.length >= stepArray.length) {
            if (disabledMap['summary']) {
                disabledMap['summary'] = false;
                changeAny = true;
            }
        }

        if (changeAny) setIsDisabled(disabledMap);
    }, [scoreBoard, isDisabled, stepArray.length]);

    const clickGoBack = (e) => {
        emptySession();
        goToPage('/');
        setSideMenuOpen(false);
    };
    const goTo = (e) => {
        goToPage(e);
        if (sidemenuOpen) setSideMenuOpen(false);
    };
    const goBackOne = () => {
        let aIn = [];

        stepArray.forEach((step) => {
            aIn.push(stepUrl(step));
        });
        aIn = [...aIn, '/oppsummering'];
        const indx = aIn.indexOf(location.pathname);

        if (indx < 1) goToPage('/');
        else {
            let n = aIn[indx - 1];
            goToPage(n);
        }
    };
    const nihssDisabled = (urlStr) => {
        const str = urlStr.replace('/', '');
        if (scoreBoard[str]) {
            return false;
        }
        return true;
    };

    return (
        <header className='Header'>
            {history.location.pathname.length > 1 &&
                history.location.pathname !== '/overview' && (
                    <Fragment>
                        <button onClick={goBackOne} className='btn-backstep'>
                            {t('web.general.btns.previous')}
                        </button>
                        <HistoryMenuEN goToPage={goToPage} t={t} />
                        <button
                            aria-label='åpne meny'
                            className='hamburgermenu is-closed'
                            onClick={() => setSideMenuOpen(!sidemenuOpen)}
                        >
                            {t('web.general.btns.menu')}
                            <span className='menu-icn'></span>
                        </button>
                        <nav
                            className={`header-nav ${
                                sidemenuOpen ? 'is-open' : 'is-closed'
                            }`}
                        >
                            <button
                                aria-label='Lukk menu'
                                className='hamburgermenu is-open'
                                onClick={() => setSideMenuOpen(!sidemenuOpen)}
                            >
                                {t('button.close')}
                                <span className='menu-icn'></span>
                            </button>
                            <button
                                className='btn-end-session'
                                onClick={clickGoBack}
                            >
                                {t('web.general.btns.cancel_survey')}
                            </button>
                            <button
                                className={`nihss-showhide ${
                                    nihhsMenuOpen ? 'is-open' : 'is-closed'
                                }${
                                    scoreBoardKeys.length === 0
                                        ? ' all-disabled'
                                        : ''
                                }`}
                                onClick={() => setNihhsMenuOpen(!nihhsMenuOpen)}
                            >
                                NIHSS
                            </button>
                            <div
                                className={`nihhs-areaurls ${
                                    nihhsMenuOpen ? 'show-area' : 'hide-area'
                                }`}
                            >
                                {stepArray.map((step, i) => {
                                    const encUrl = stepUrl(step);
                                    return (
                                        <UrlButton
                                            key={`${step}--${i}`}
                                            onClick={goTo}
                                            url={encUrl}
                                            isCurrent={
                                                location.pathname === encUrl
                                            }
                                            classes='is-nihss'
                                            isDisabled={nihssDisabled(encUrl)}
                                        >
                                            <span className='nihss-ref'>
                                                {step.NIHSSref}
                                            </span>{' '}
                                            {step.title}
                                        </UrlButton>
                                    );
                                })}
                            </div>
                            <UrlButton
                                onClick={goTo}
                                url={'/oppsummering'}
                                isCurrent={
                                    location.pathname === '/oppsummering'
                                }
                                isDisabled={isDisabled.summary}
                            >
                                {t('web.summary.title')}
                            </UrlButton>
                        </nav>
                    </Fragment>
                )}
        </header>
    );
};

export default Header;
