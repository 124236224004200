import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { languageByDomainDetector } from './languageByDomainDetector';
import nb from './nb.json';
import en from './en.json';

//const languageDetector = new LanguageDetector();
const DEFAULT_LOCALE = 'nb';

i18n.use(languageByDomainDetector(DEFAULT_LOCALE))
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init(
        {
            debug: false,
            //customDetectors: [languageByDomainDetector(DEFAULT_LOCALE)],
            //supportedLngs: ['nb', 'en'],
            resources: {
                nb: {
                    translations: nb
                },
                en: {
                    translations: en
                }
            },
            //lng: 'nb', // Default language

            ns: ['translations'],
            defaultNS: 'translations',
            keySeparator: '.', // key separator
            fallbackLng: 'nb',
            interpolation: {
                escapeValue: false // React already escapes values
            }
            //load: 'languageOnly'
            // detection: {
            //     order: ['domain']
            // }
        },
        (err, t) => {
            if (err) {
                console.error('i18n init error', err);
            }
        }
    );

export default i18n;
