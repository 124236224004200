import React, { useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderEN from './HeaderEN/Header';
import StartPageEN from './StartPageEN/StartPage';
import NihssStepEN from './NihssStepEN/NihssStep';
import SummaryEN from './SummaryEN/Summary';
import { doEncode } from './generalFunctions';
import { stepArr, stepObjName } from './variablesEN/generalVariables';
import {
    setScoreS,
    getScoreS,
    getAge,
    setAgeS
} from './variables/sessionVariables';
import './App.scss';

function App() {
    const { t } = useTranslation();
    let history = useHistory();
    const [scoreBoard, setScoreBoard] = useState({});
    const stepArray = stepArr(t);
    const [age, setAge] = useState('60 years');

    const pushToHistory = (val) => {
        history.push(val);
    };
    useEffect(() => {
        let hasScoreBoard = getScoreS();
        if (hasScoreBoard) {
            setScoreBoard(JSON.parse(hasScoreBoard));
        }
        let hasAge = getAge();
        if (hasAge) {
            setAge(JSON.parse(hasAge));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const updateScore = (itm, score) => {
        let updatedScore = { ...scoreBoard };
        updatedScore[itm] = score;
        setScoreBoard(updatedScore);
        setScoreS(updatedScore);
    };
    const updateAge = (content) => {
        setAge(content);
        setAgeS(content);
    };
    return (
        <div className='App'>
            <HeaderEN
                history={history}
                goToPage={pushToHistory}
                scoreBoard={scoreBoard}
                t={t}
            />
            <main>
                <Switch>
                    <Route
                        path='/'
                        render={() => (
                            <StartPageEN
                                toNext={() =>
                                    pushToHistory(
                                        `/${doEncode(
                                            stepObjName(stepArray[0] || '')
                                        )}`
                                    )
                                }
                                t={t}
                            />
                        )}
                        exact
                    />
                    {stepArray.map((step, ind) => {
                        return (
                            <Route
                                key={ind}
                                path={`/${doEncode(stepObjName(step))}`}
                                render={() => (
                                    <NihssStepEN
                                        stepObj={step}
                                        prevObj={
                                            ind === 0
                                                ? { urlName: '/' }
                                                : stepArray[ind - 1]
                                        }
                                        nextObj={stepArray[ind + 1]}
                                        updateScore={updateScore}
                                        goToPage={pushToHistory}
                                        age={age}
                                        setAge={updateAge}
                                        t={t}
                                    />
                                )}
                            />
                        );
                    })}
                    <Route
                        path='/oppsummering'
                        render={() => (
                            <SummaryEN
                                scoreBoard={scoreBoard}
                                age={age}
                                toNext={() => pushToHistory('/')}
                                t={t}
                            />
                        )}
                    />
                </Switch>
            </main>
        </div>
    );
}

export default App;
