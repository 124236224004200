import { doEncode } from '../generalFunctions';

class NihssStep {
    constructor(urlName, title, task, NIHSSref, refId, answerOpts) {
        this.urlName = urlName;
        this.title = title;
        this.task = task;
        this.NIHSSref = NIHSSref;
        this.refId = refId;
        this.answerOpts = answerOpts;
    }
}
class OptionObj {
    constructor(title, img, score, width) {
        this.title = title;
        if (img instanceof Object) {
            this.imgs = img;
        } else {
            this.img = img;
        }
        this.score = score;
        this.width = width;
    }
}

export const stepObjName = (stepObj) =>
    `${stepObj.NIHSSref || ''}-${stepObj.refId || '1'}`;
export const stepUrl = (stepObj) => `/${doEncode(stepObjName(stepObj))}`;

const bevistthetOpts = (t) => [
    new OptionObj(t('form.consciousness.awake'), 'Blikkbevegelse-2-1-0', 0, 50),
    new OptionObj(t('form.consciousness.drowsy_1'), 'Bevissthet-1a-1', 1, 50),
    new OptionObj(t('form.consciousness.drowsy_2'), 'Bevissthet-1a-2', 2, 50),
    new OptionObj(t('form.consciousness.drowsy_3'), 'Bevissthet-1a-3', 3, 50)
];
export const stepBevissthet = (t) =>
    new NihssStep(
        'Bevissthet',
        t('form.consciousness.title'),
        t('form.consciousness.info'),
        '1a',
        1,
        bevistthetOpts(t)
    );

const optsAge = (t) => [
    new OptionObj(
        t('form.age_orientation.correct_answer'),
        'Orientering-alder-1b-1-1',
        0,
        50
    ),
    new OptionObj(
        t('form.age_orientation.wrong_answer'),
        'Orientering-alder-1b-1-0',
        1,
        50
    )
];
export const stepAge = (t) =>
    new NihssStep(
        'OrienteringAlder',
        t('form.age_orientation.title'),
        t('form.age_orientation.info'),
        '1b',
        1,
        optsAge(t)
    );

const monthOpts = (t) => [
    new OptionObj(
        t('form.age_orientation.correct_answer'),
        'Orientering-maaned-1b-2-0',
        0,
        50
    ),
    new OptionObj(
        t('form.age_orientation.wrong_answer'),
        'Orientering-maaned-1b-2-1',
        1,
        50
    )
];
export const stepMonth = (t) =>
    new NihssStep(
        'Orienteringmaaned',
        t('form.month.title'),
        t('form.month.info'),
        '1b',
        2,
        monthOpts(t)
    );

const sprakOpts = (t) => [
    new OptionObj(t('form.flashlight_test.normal'), 'Spraak-9-0', 0, 50),
    new OptionObj(
        t('form.flashlight_test.conversation_possible'),
        'Spraak-9-1',
        1,
        50
    ),
    new OptionObj(
        t('form.flashlight_test.conversation_difficult'),
        'Spraak-9-2',
        2,
        50
    ),
    new OptionObj(
        t('form.flashlight_test.no_conversation'),
        'Spraak-9-3',
        3,
        50
    )
];
export const stepSpråk = (t) =>
    new NihssStep(
        'Sprak',
        t('form.flashlight_test.title'),
        t('form.flashlight_test.info'),
        '9',
        1,
        sprakOpts(t)
    );

const taleOpts = (t) => [
    new OptionObj(t('form.speech.normal'), 'Tale-10-0', 0, 50),
    new OptionObj(t('form.speech.difficult'), 'Tale-10-1', 1, 50),
    new OptionObj(t('form.speech.no_speech'), 'Tale-10-2', 2, 50)
];
export const stepTale = (t) =>
    new NihssStep(
        'tale',
        t('form.speech.title'),
        t('form.speech.info'),
        '10',
        1,
        taleOpts(t)
    );

const kommandoOpts = (t) => [
    new OptionObj(
        t('form.command.eyes_hands_correctly'),
        'Kommando-1c-1-0',
        0,
        50
    ),
    new OptionObj(t('form.command.eyes_correctly'), 'Kommando-1c-1-1', 1, 50),
    new OptionObj(t('form.command.hands_correctly'), 'Kommando-1c-1-2', 1, 50),
    new OptionObj(t('form.command.eyes_hands_wrong'), 'Kommando-1c-1-3', 2, 50)
];
export const stepKommando = (t) =>
    new NihssStep(
        'kommando',
        t('form.command.title'),
        t('form.command.info'),
        '1c',
        1,
        kommandoOpts(t)
    );

const blikkBevOpts = (t) => [
    new OptionObj(t('general.normal'), 'Blikkbevegelse-2-1-0', 0, 100),
    new OptionObj(
        t('form.gaze.restricted'),
        { left: 'Blikkbevegelse-2-1-1', right: 'Blikkbevegelse-2-1-2' },
        1,
        100
    ),
    new OptionObj(
        t('form.gaze.fixed'),
        { left: 'Blikkbevegelse-2-1-3', right: 'Blikkbevegelse-2-1-4' },
        2,
        100
    )
];
export const stepBlikkBevegelse = (t) =>
    new NihssStep(
        'blikkbevegelse',
        t('form.gaze.title'),
        t('form.gaze.info'),
        '2',
        1,
        blikkBevOpts(t)
    );

const synsfeltOpts = (t) => [
    new OptionObj(t('form.vision.both_sides'), 'Blikkbevegelse-2-1-0', 0, 100),
    new OptionObj(
        t('form.vision.one_side'),
        { left: 'Synsfelt-3-1-2', right: 'Synsfelt-3-1-1' },
        1,
        100
    ),
    new OptionObj(
        t('form.vision.no_vision_one_side'),
        { left: 'Synsfelt-3-1-4', right: 'Synsfelt-3-1-3' },
        2,
        100
    ),
    new OptionObj(
        t('form.vision.no_vision_both_sides'),
        'Synsfelt-3-1-5',
        3,
        100
    )
];
export const stepSynsfelt = (t) =>
    new NihssStep(
        'synsfelt',
        t('form.vision.title'),
        t('form.vision.info'),
        '3',
        1,
        synsfeltOpts(t)
    );

const syneglectOpts = (t) => [
    new OptionObj(t('general.normal'), 'Neglekt-11-1-0', 0, 100),
    new OptionObj(
        t('form.attention.decreased'),
        { left: 'Neglekt-11-1-2', right: 'Neglekt-11-1-1' },
        1,
        100
    )
];
export const stepSynsfeltNeglect = (t) =>
    new NihssStep(
        'synsfeltoppmerksomhet',
        t('form.attention.title'),
        t('form.attention.info'),
        '11',
        1,
        syneglectOpts(t)
    );

const ansiktOpts = (t) => [
    new OptionObj(t('general.normal'), 'Ansikt-4-1-0', 0, 100),
    new OptionObj(
        t('form.face.paralysis_one_side'),
        { left: 'Ansikt-4-1-2', right: 'Ansikt-4-1-1' },
        1,
        100
    ),
    new OptionObj(
        t('form.face.paralysis_lower_half'),
        { left: 'Ansikt-4-1-4', right: 'Ansikt-4-1-3' },
        2,
        100
    ),
    new OptionObj(
        t('form.face.paralysis'),
        { left: 'Ansikt-4-1-6', right: 'Ansikt-4-1-5' },
        3,
        100
    )
];
export const stepAnsikt = (t) =>
    new NihssStep(
        'ansikt',
        t('form.face.title'),
        t('form.face.info'),
        '4',
        1,
        ansiktOpts(t)
    );

const armLeftOpts = (t) => [
    new OptionObj(t('general.normal'), 'Kraft-i-arm-5-1-0', 0, 100),
    new OptionObj(t('form.arm.drifts_lower'), 'Kraft-i-arm-5-1-1', 1, 100),
    new OptionObj(t('form.arm.drifts_down'), 'Kraft-i-arm-5-1-2', 2, 100),
    new OptionObj(t('form.arm.muscle_movement'), 'Kraft-i-arm-5-1-3', 3, 100),
    new OptionObj(t('form.arm.no_lift'), 'Kraft-i-arm-5-1-4', 4, 100)
];
export const stepArmLeft = (t) =>
    new NihssStep(
        'kraftiarmvenstre',
        t('form.arm.left_title'),
        t('form.arm.info'),
        '5',
        1,
        armLeftOpts(t)
    );

const armRightOpts = (t) => [
    new OptionObj(t('general.normal'), 'Kraft-i-arm-5-2-0', 0, 100),
    new OptionObj(t('form.arm.drifts_lower'), 'Kraft-i-arm-5-2-1', 1, 100),
    new OptionObj(t('form.arm.drifts_down'), 'Kraft-i-arm-5-2-2', 2, 100),
    new OptionObj(t('form.arm.muscle_movement'), 'Kraft-i-arm-5-2-3', 3, 100),
    new OptionObj(t('form.arm.no_lift'), 'Kraft-i-arm-5-2-4', 4, 100)
];
export const stepArmRight = (t) =>
    new NihssStep(
        'kraftiarmhoyre',
        t('form.arm.right_title'),
        t('form.arm.info'),
        '5',
        2,
        armRightOpts(t)
    );

const footLeftOpts = (t) => [
    new OptionObj(t('general.normal'), 'Kraft-i-bein-6-1-0', 0, 100),
    new OptionObj(t('form.arm.drifts_lower'), 'Kraft-i-bein-6-1-1', 1, 100),
    new OptionObj(t('form.arm.drifts_down'), 'Kraft-i-bein-6-1-2', 2, 100),
    new OptionObj(t('form.arm.muscle_movement'), 'Kraft-i-bein-6-1-3', 3, 100),
    new OptionObj(t('form.arm.no_lift'), 'Kraft-i-bein-6-1-4', 4, 100)
];
export const stepFootLeft = (t) =>
    new NihssStep(
        'kraftibenvenstre',
        t('form.leg.left_title'),
        t('form.leg.info'),
        '6',
        1,
        footLeftOpts(t)
    );

const footRightOpts = (t) => [
    new OptionObj(t('general.normal'), 'Kraft-i-bein-6-2-0', 0, 100),
    new OptionObj(t('form.arm.drifts_lower'), 'Kraft-i-bein-6-2-1', 1, 100),
    new OptionObj(t('form.arm.drifts_down'), 'Kraft-i-bein-6-2-2', 2, 100),
    new OptionObj(t('form.arm.muscle_movement'), 'Kraft-i-bein-6-2-3', 3, 100),
    new OptionObj(t('form.arm.no_lift'), 'Kraft-i-bein-6-2-4', 4, 100)
];
export const stepFootRight = (t) =>
    new NihssStep(
        'kraftibenhoyre',
        t('form.leg.right_title'),
        t('form.leg.info'),
        '6',
        2,
        footRightOpts(t)
    );

const feelArmOpts = (t) => [
    new OptionObj(t('form.skin.normal'), 'Skinfeeling-arm-8-1-0', 0, 100),
    new OptionObj(
        t('form.skin.decreased'),
        { left: 'Skinfeeling-arm-8-1-2', right: 'Skinfeeling-arm-8-1-1' },
        1,
        100
    )
];
export const stepFeelArm = (t) =>
    new NihssStep(
        'hudfolelsearm',
        t('form.skin.arm_title'),
        t('form.skin.info'),
        '8',
        1,
        feelArmOpts(t)
    );

const feelLegOpts = (t) => [
    new OptionObj(t('form.skin.normal'), 'Skinfeeling-bein-8-2-0', 0, 100),
    new OptionObj(
        t('form.skin.decreased_leg'),
        { left: 'Skinfeeling-bein-8-2-2', right: 'Skinfeeling-bein-8-2-1' },
        1,
        100
    )
];
export const stepFeelLeg = (t) =>
    new NihssStep(
        'hudfolelseben',
        t('form.skin.leg_title'),
        t('form.skin.info_2'),
        '8',
        2,
        feelLegOpts(t)
    );

const neglectArmOpts = (t) => [
    new OptionObj(t('general.normal'), 'Neglekt-11-2-0', 0, 100),
    new OptionObj(
        t('form.shoulder.decreased'),
        { left: 'Neglekt-11-2-2', right: 'Neglekt-11-2-1' },
        1,
        100
    )
];
export const stepNeglectArm = (t) =>
    new NihssStep(
        'folelseoppmerksomhet',
        t('form.shoulder.title'),
        t('form.shoulder.info'),
        '11',
        2,
        neglectArmOpts(t)
    );

const ataksiTopOpts = (t) => [
    new OptionObj(t('general.normal'), 'Koordinasjon-7-1-0', 0, 100),
    new OptionObj(
        t('form.finger.choppy'),
        { left: 'Koordinasjon-7-1-2', right: 'Koordinasjon-7-1-1' },
        1,
        100
    )
];
export const stepAtaksiTop = (t) =>
    new NihssStep(
        'ataksiarms',
        t('form.finger.title'),
        t('form.finger.info'),
        '7',
        1,
        ataksiTopOpts(t)
    );

const ataksiLegOpts = (t) => [
    new OptionObj(t('general.normal'), 'Koordinasjon-7-2-0', 0, 100),
    new OptionObj(
        t('form.finger.choppy'),
        { left: 'Koordinasjon-7-2-2', right: 'Koordinasjon-7-2-1' },
        1,
        100
    )
];
export const stepAtaksiLeg = (t) =>
    new NihssStep(
        'ataksileg',
        t('form.heel_knee.title'),
        t('form.heel_knee.info'),
        '7',
        2,
        ataksiLegOpts(t)
    );

export const stepArr = (t) => [
    stepBevissthet(t),
    stepAge(t),
    stepMonth(t),
    stepSpråk(t),
    stepTale(t),
    stepKommando(t),
    stepBlikkBevegelse(t),
    stepSynsfelt(t),
    stepSynsfeltNeglect(t),
    stepAnsikt(t),
    stepArmLeft(t),
    stepArmRight(t),
    stepFootLeft(t),
    stepFootRight(t),
    stepFeelArm(t),
    stepFeelLeg(t),
    stepNeglectArm(t),
    stepAtaksiTop(t),
    stepAtaksiLeg(t)
];
