import React from 'react';
import GenericVitaliaDisplay from './GenericVitaliaDisplay';
import GenericVitaliaInput from './GenericVitaliaInput';
import InputSwitch from '../InputSwitch/InputSwitch';
import VitaliaErrorMsg from '../VitaliaErrorMsg/VitaliaErrorMsg';
import './VitaliaPage.scss';

const systoliskMinMax = { min: 50, max: 260 };
const diastoliskMinMax = { min: 30, max: 180 };
const BSLeftMinMax = { min: 0, max: 33 };
const BSRightMinMax = { min: 0, max: 9 };
const TLeftMinMax = { min: 0, max: 43 };
const TRightMinMax = { min: 0, max: 9 };
const PulseMinMax = { min: 0, max: 240 };
const zeroMinMax = { min: 0, max: 0 };

const validate = (value, minMax) => {
    if (value === null) {
        return null;
    }

    const intValue = parseInt(value);

    if (isNaN(intValue)) {
        return false;
    }
    return intValue <= minMax.max && intValue >= minMax.min;
};

const VitaliaPage = (props) => {
    const {
        vitalia,
        setVitalia,
        toNext,
        bloodSugar,
        setBloodSugar,
        temp,
        setTemp,
        pulse,
        setPulse,
        t
    } = props;

    const changeCheckBP = (checked) => {
        setVitalia('known', !checked);
    };
    const changeSy = (e) => {
        const val = e.target.value;
        setVitalia('bpSystol', val);
    };
    const changeDi = (e) => {
        const val = e.target.value;
        setVitalia('bpDiast', val);
    };

    const changeCheckBS = (checked) => {
        setBloodSugar('known', !checked);
    };
    const changeCheckT = (checked) => {
        setTemp('known', !checked);
    };
    const changeCheckP = (checked) => {
        setPulse('known', !checked);
    };

    const systoliskIsValid = validate(vitalia.bpSystol, systoliskMinMax);
    const diastoliskIsValid = validate(vitalia.bpDiast, diastoliskMinMax);

    const BSLeftIsValid = validate(bloodSugar.left, BSLeftMinMax);
    const BSRightIsValid = validate(
        bloodSugar.right,
        parseInt(bloodSugar.left) === parseInt(25) ? zeroMinMax : BSRightMinMax
    );

    const TLeftIsValid = validate(temp.left, TLeftMinMax);
    const TRightIsValid = validate(
        temp.right,
        parseInt(temp.left) === parseInt(45) ? zeroMinMax : BSRightMinMax
    );

    const pulseIsValid = validate(pulse.value, PulseMinMax);

    const isStepValidForObj = (known, isValidChecks) => {
        return !known || isValidChecks;
    };

    let stepIsValid = true;

    stepIsValid =
        stepIsValid &&
        isStepValidForObj(vitalia.known, systoliskIsValid && diastoliskIsValid);
    stepIsValid =
        stepIsValid &&
        isStepValidForObj(bloodSugar.known, BSLeftIsValid && BSRightIsValid);
    stepIsValid =
        stepIsValid &&
        isStepValidForObj(temp.known, TLeftIsValid && TRightIsValid);
    stepIsValid = stepIsValid && isStepValidForObj(pulse.known, pulseIsValid);

    return (
        <div>
            <h1>{t('form.vitals.title')}</h1>

            <div className='VitaliaPage'>
                <div className='card'>
                    <GenericVitaliaDisplay
                        className='vitalia-item'
                        title={t('form.vitals.blood_pressure')}
                        obj={vitalia}
                        changeCheck={changeCheckBP}
                        info={`${vitalia.bpSystol}/${vitalia.bpDiast} mmHg`}
                        t={t}
                    />
                    <GenericVitaliaInput
                        className='vitalia-item'
                        obj={vitalia}
                        firstTitle={'systolisk'}
                        firstValue={vitalia.bpSystol}
                        changeFirst={changeSy}
                        firstMin={systoliskMinMax.min}
                        firstMax={systoliskMinMax.max}
                        firstIsValid={systoliskIsValid}
                        secondTitle={'diastolisk'}
                        secondValue={vitalia.bpDiast}
                        changeSecond={changeDi}
                        secondMin={diastoliskMinMax.min}
                        secondMax={diastoliskMinMax.max}
                        secondIsValid={diastoliskIsValid}
                        seperator={'/'}
                        t={t}
                    />
                </div>

                <div className='card'>
                    <GenericVitaliaDisplay
                        className='vitalia-item'
                        title={t('form.vitals.blood_sugar')}
                        obj={bloodSugar}
                        changeCheck={changeCheckBS}
                        info={`${bloodSugar.left}.${bloodSugar.right} mmol/l`}
                        t={t}
                    />
                    <GenericVitaliaInput
                        className='vitalia-item'
                        obj={bloodSugar}
                        firstTitle={'bloodsugerLeft'}
                        firstValue={bloodSugar.left}
                        changeFirst={(e) =>
                            setBloodSugar('left', e.target.value)
                        }
                        firstMin={BSLeftMinMax.min}
                        firstMax={BSLeftMinMax.max}
                        firstIsValid={BSLeftIsValid}
                        secondTitle={'bloodsugerRight'}
                        secondValue={bloodSugar.right}
                        changeSecond={(e) =>
                            setBloodSugar('right', e.target.value)
                        }
                        secondMin={BSRightMinMax.min}
                        secondMax={BSRightMinMax.max}
                        secondIsValid={BSRightIsValid}
                        maxValue={25}
                        seperator={'.'}
                        t={t}
                    />
                </div>

                <div className='card'>
                    <GenericVitaliaDisplay
                        className='vitalia-item'
                        title={t('form.vitals.temperature')}
                        obj={temp}
                        changeCheck={changeCheckT}
                        info={`${temp.left}.${temp.right} °C`}
                        t={t}
                    />
                    <GenericVitaliaInput
                        className='vitalia-item'
                        obj={temp}
                        firstTitle={'tempLeft'}
                        firstValue={temp.left}
                        changeFirst={(e) => setTemp('left', e.target.value)}
                        firstMin={TLeftMinMax.min}
                        firstMax={TLeftMinMax.max}
                        firstIsValid={TLeftIsValid}
                        secondTitle={'tempRight'}
                        secondValue={temp.right}
                        changeSecond={(e) => setTemp('right', e.target.value)}
                        secondMin={TRightMinMax.min}
                        secondMax={TRightMinMax.max}
                        secondIsValid={TRightIsValid}
                        maxValue={45}
                        seperator={'.'}
                        t={t}
                    />
                </div>

                <div className='card'>
                    <ul className='vitalia-list-page'>
                        <li className='list-section'>
                            <div>
                                <h2>{t('form.vitals.pulse')}</h2>
                            </div>
                            <div className='value'>
                                <p>
                                    {pulse.known
                                        ? `${pulse.value} / min`
                                        : t('general.unknown')}
                                </p>
                            </div>
                        </li>
                        <li className='list-section'>
                            <InputSwitch
                                checked={pulse.known === false}
                                setChecked={changeCheckP}
                                lbl={pulse.lbl}
                                name={'pulse-switch'}
                            />
                        </li>
                    </ul>
                    <section
                        className={`set-verdi vitalia-item ${
                            pulse.known ? 'is-known' : 'is-unknown'
                        }`}
                    >
                        <h3>{t('web.vitals.value')}</h3>
                        <div className='bp-input'>
                            <label aria-label={'pulse'}>
                                <input
                                    type='number'
                                    pattern='[0-9]{1,3}'
                                    name={'pulse'}
                                    value={pulse.value || ''}
                                    onChange={(e) =>
                                        setPulse('value', e.target.value)
                                    }
                                    disabled={pulse.known === false}
                                    min={PulseMinMax.min}
                                    max={PulseMinMax.max}
                                    required={pulse.known}
                                />
                                <VitaliaErrorMsg
                                    show={pulseIsValid}
                                    min={PulseMinMax.min}
                                    max={PulseMinMax.max}
                                />
                            </label>
                        </div>
                    </section>
                </div>

                <button
                    className='next-btn'
                    onClick={toNext}
                    disabled={!stepIsValid}
                >
                    <span>{t('web.summary.title')}</span>
                </button>
            </div>
        </div>
    );
};

export default VitaliaPage;
